import { useState, useEffect } from "react";
import { Flex, Image, Center } from "@chakra-ui/react";
import { TextField } from "../../components/inputs/TextField";
import { Texts } from "../../components/texts/Texts";
import { Title } from "../../components/texts/Title";
import Copy from "@iconscout/react-unicons/icons/uil-copy";
import Check from "@iconscout/react-unicons/icons/uil-check";
import Times from "@iconscout/react-unicons/icons/uil-times";
import { DefaultButton } from "../../components/buttons/Button";

import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { fetchProfile } from "../../backApi/fetchProfile";
import { referAFriend } from "../../backApi/raf/referAFriend";
import CheckCircle from "@iconscout/react-unicons/icons/uil-check-circle";
import { Contact } from "src/types";

function validateEmail(email: string) {
  const mailCheck =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return mailCheck.test(String(email).toLowerCase());
}

function PictureElement() {
  return (
    <Flex direction="column">
      <Flex
        direction="column"
        w={["90vw", "456px", "456px", "456px"]}
        minH={["100%", "540px"]}
        bg="#F5F5F5"
        rounded="12px"
        p="24px"
      >
        <Flex>
          <Image
            rounded="8px"
            minW={["100%", "408px"]}
            h={["90vw", "364px"]}
            src="https://dalma-images.s3.eu-west-3.amazonaws.com/image+v2+carre%CC%81e.png"
          ></Image>
        </Flex>

        <Flex pb="6px" pt="24px" pl="2">
          <Flex flex={1} direction="column">
            <Texts color="#212121" fontSize="12px">
              Pour votre ami
            </Texts>
            <Title color="#4E68F6" fontSize="24px">
              1 mois
            </Title>
            <Texts color="#616161" fontSize="12px">
              d’assurance santé offerte
            </Texts>
          </Flex>

          <Flex flex={1} direction="column">
            <Texts color="#212121" fontSize="12px">
              Pour vous
            </Texts>
            <Title color="#00704F" fontSize="24px">
              gagnez jusqu’à 200€
            </Title>
            <Texts color="#616161" fontSize="12px">
              sur votre compte en banque
            </Texts>
          </Flex>

          <Flex></Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export const Sponsorship = () => {
  const [email, setEmail] = useState("");
  const [profile, setProfile] = useState<Contact>();
  const [copied, setCopied] = useState(false);
  const [sent, setSent] = useState(false);

  const history = useHistory();

  useEffect(() => {
    async function onLoad() {
      try {
        await Auth.currentAuthenticatedUser();
        fetchProfile(setProfile);
      } catch (e) {
        // TODO sentry
      }
    }
    onLoad();
  }, [history]);

  return (
    <Center
      minW="100vw"
      minH="100vh"
      mt={["50px", "50px", 0]}
      p={["25px", "40px", "50px", "50px", "100px", "200px"]}
    >
      <Center
        cursor="pointer"
        onClick={() => history.replace("/")}
        bg="#DBEBFF"
        w="44px"
        h="44px"
        position="absolute"
        right="5vw"
        top="5vh"
        rounded="full"
      >
        <Times color="#4E68F6" size="25px" />
      </Center>

      <Flex w="100%">
        <Flex align="flex-start" direction="column" mr="5vw" maxW="100%">
          <Flex mb="13px">
            <Title fontSize={["24px", "24px", "32px"]} color="#212121">
              Gagnez des mois d’assurance en invitant vos amis à rejoindre l’aventure
            </Title>
          </Flex>

          <Flex mb="27px">
            <Texts color="#757575" fontWeight="400px">
              Chez Dalma, nous voulons rendre le bien-être animal accessible au plus grand nombre.
              Aidez-nous à faire de Dalma une grande famille en invitant vos proches à rejoindre
              l’aventure
            </Texts>
          </Flex>

          <Flex mb="17px">
            <Title fontSize="20px" color="#212121">
              Comment ça marche ?
            </Title>
          </Flex>

          <Flex
            justifyContent="center"
            w="100%"
            my="5"
            display={["flex", "flex", "none", "none", "none", "none", "none"]}
          >
            <PictureElement />
          </Flex>

          <Flex mb="14px">
            <Texts color="#757575">
              <b style={{ color: "#222222" }}>1 -</b> Voici votre code de parrainage
            </Texts>
          </Flex>

          <Flex mb="32px">
            <Flex
              w={["90vw", "90vw", "260px", "280px", "360px"]}
              h="52px"
              bg="#FAEEE6"
              rounded="8px"
            >
              <Flex minW="60%" p="15px" align="flex-start">
                <Flex>
                  <Texts color={copied ? "#00704F" : "#212121"} fontWeight="400">
                    {!copied && profile ? profile.raf_code : "Code copié"}
                  </Texts>
                </Flex>
                {copied && (
                  <Flex ml="1" mt="-1px">
                    <Check color="#00704F" />
                  </Flex>
                )}
              </Flex>

              <Flex mr="15px" w="100%" align="center" h="52px" justifyContent="flex-end">
                <Flex
                  onClick={() => {
                    navigator.clipboard.writeText(profile?.raf_code || "");
                    setTimeout(() => {
                      setCopied(false);
                    }, 1000);
                    setCopied(true);
                  }}
                  cursor="pointer"
                  bg="#FE6F18"
                  h="36px"
                  p="10px"
                  rounded="8px"
                  align="center"
                >
                  <Flex>
                    <Copy color="white" size="15px" />
                  </Flex>
                  <Flex>
                    <Texts ml="10px" color="white" fontSize="12px">
                      Copier
                    </Texts>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex mb="14px">
            <Texts color="#757575">
              <b style={{ color: "#222222" }}>2 -</b> Partager votre code par email, SMS, Facebook,
              LinkedIn, chien voyageur…
            </Texts>
          </Flex>

          <Flex mb="32px">
            <Flex>
              <TextField
                autocomplete="mail"
                w={["90vw", "90vw", "260px", "280px", "360px"]}
                name="mail"
                errorsMessage={
                  email && email.length > 0 && !validateEmail(email.trim())
                    ? "Veuillez entrer un mail valide"
                    : undefined
                }
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Adresse email"
                value={email}
              />
            </Flex>
            <Flex ml="3">
              {!sent && profile ? (
                <DefaultButton
                  w="100%"
                  px="10"
                  onClick={() => {
                    referAFriend(email, profile);
                    setEmail("");

                    setTimeout(() => {
                      setSent(false);
                    }, 2000);
                    setSent(true);
                  }}
                  type={"button"}
                  isDisabled={!validateEmail(email.trim())}
                >
                  Envoyer
                </DefaultButton>
              ) : (
                <Center h="52px" minW="140px" rounded="full" bg="refundState">
                  <CheckCircle size="16px" color="#00704F" />
                  <Texts ml="2" fontSize="14px" color="#00704F">
                    Envoyé
                  </Texts>
                </Center>
              )}
            </Flex>
          </Flex>

          <Flex mb="32px">
            <Texts color="#757575">
              <b style={{ color: "#222222" }}>3 -</b> Votre ami n’a plus qu’à inscrire votre code de
              parrainage au moment du paiement sur le site. Il profitera d’une remise immédiate d’un
              mois d’assurance santé offert{" "}
            </Texts>
          </Flex>

          <Flex>
            <Texts color="#757575">
              <b style={{ color: "#222222" }}>4 -</b> Une fois votre ami assuré,{" "}
              <b>gagnez jusqu’à 200€</b> sur votre compte en banque
            </Texts>
          </Flex>
        </Flex>
        <Flex display={["none", "none", "flex", "flex", "flex", "flex"]}>
          <PictureElement />
        </Flex>
      </Flex>
    </Center>
  );
};
