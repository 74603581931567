import { DialogOverlay, DialogContent } from "@reach/dialog";
import { AnimatePresence, motion } from "framer-motion";

import { WithChildren } from "src/types";
import { styled, theme } from "src/styles/stitches/theme";

import { keyframes } from "@stitches/react";
import { Icon } from "src/components/01_atoms/icon";
import { useDevice } from "src/hooks/useDevice";

export const Overlay = styled(DialogOverlay, {
  "&[data-reach-dialog-overlay]": {
    display: "none",

    "@bp3": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      background: theme.colors.fillOverlay,
      zIndex: 999,
      cursor: "pointer",
    },
  },
  "& > [data-reach-dialog-content]": {
    display: "none",

    "@bp3": {
      display: "block",
      background: theme.colors.fillSecondary,
      borderRadius: theme.radii[32],
      padding: 0,
      width: "26rem",
      cursor: "default",
    },
  },

  variants: {
    fullScreen: {
      true: {
        "&[data-reach-dialog-overlay]": {
          width: "100vw",
          height: "100vh",
          display: "flex",
          background: theme.colors.fillOverlay,
          zIndex: 999,
          cursor: "pointer",
          padding: 0,
          margin: 0,
        },
        "& > [data-reach-dialog-content]": {
          width: "100vw",
          height: "100vh",
          display: "flex",
          background: theme.colors.fillSecondary,
          borderRadius: 0,
          padding: 0,
          margin: 0,
          cursor: "default",
        },
      },
    },
    isMobile: {
      true: {
        backgroundColor: "white !important",
      },
    },
  },
});

const slideIp = keyframes({
  "0%": {
    transform: "translateY(80px)",
  },
  "100%": {
    transform: "translateY(0px)",
  },
});

export const Content = styled(DialogContent, {
  transform: "translateY(0px)",
  animation: `ease-out 0.2s ${slideIp}`,

  variants: {
    fullScreen: {
      true: {
        width: "100vw",
        height: "100vh",
        borderRadius: 0,
      },
    },
  },
});

export const CloseIcon = styled("div", {
  display: "flex",
  position: "absolute",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  top: "3rem",
  right: "3rem",
  width: "2.75rem",
  height: "2.75rem",
  borderRadius: "0.75rem",
  border: "1px solid #DBEBFF",
  backgroundColor: "#FFFFFF",
});

const MotionDialogOverlay = motion(Overlay);

type Props = WithChildren<{
  isOpen: boolean;
  label: string;
  onDismiss: () => void;
  fullScreen?: boolean;
  style?: any;
}>;

export function Modal({ children, isOpen, label, onDismiss, fullScreen, style }: Props) {
  const { isMobile } = useDevice();

  return (
    <AnimatePresence>
      {isOpen && (
        <MotionDialogOverlay
          onDismiss={onDismiss}
          aria-label={label}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3, ease: "easeOut" }}
          fullScreen={fullScreen}
          isMobile={isMobile()}
        >
          <Content aria-label={label} fullScreen={fullScreen} style={style}>
            {fullScreen && !isMobile() ? (
              <CloseIcon onClick={onDismiss}>
                <Icon name="outlinedClose" color="iconBaseSubdued" size={16} />
              </CloseIcon>
            ) : null}
            {children}
          </Content>
        </MotionDialogOverlay>
      )}
    </AnimatePresence>
  );
}
