import { styled } from "src/styles/stitches/theme";

export const WrapperContent = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "0 1rem",

  "@bp3": {
    width: "27rem",
    marginRight: "13.25rem",
    justifyContent: "center",
    padding: "0",
  },
});

export const WrapperTitle = styled("div", {
  display: "flex",
  flexDirection: "column",
  marginTop: "21rem",
  gap: "0.6rem",

  "@bp3": {
    gap: "1rem",
    marginBottom: "3rem",
    marginTop: "5rem",
  },
});

export const CopyRAFCard = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0.6rem 1rem",
  backgroundImage: "url(/assets/media/background-raf-copy-card.png)",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "0% 0%",
  backgroundSize: "contain",
  margin: "1.6rem 0",

  "@bp3": {
    padding: "1rem",
    margin: "0rem 0rem",
    marginBottom: "3rem",
  },
});

export const PushAppCard = styled("div", {
  display: "flex",
  width: "100%",
  backgroundColor: "#3b4cbd",
  padding: "1rem",
  borderRadius: 20,
  position: "relative",
  overflow: "hidden",
  marginBottom: "1.6rem",

  "@bp3": {
    margin: "3rem 0",
  },
});

export const WrapperRAFCardTitle = styled("div", {
  display: "flex",
  flexDirection: "column",

  "@bp3": {},
});

export const WrappperContentPushAppCard = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "100%",
  marginRight: "2rem",

  "@bp3": {
    marginRight: "0rem",
  },
});

export const WrapperTitlePushAppCard = styled("div", {
  display: "flex",
  flexDirection: "column",
  maxWidth: "204px",
  gap: "4px",

  "@bp3": {},
});

export const WrapperQRCode = styled("div", {
  display: "none",
  justifyContent: "center",
  width: "96px",
  height: "96px",
  backgroundColor: "#4957bc",
  borderRadius: 12,
  padding: "12px",
  marginLeft: 20,

  "@bp3": {
    display: "flex",
  },
});

export const QRCode = styled("div", {
  backgroundImage: "url(/assets/media/QR-code.png)",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "0% 0%",
  backgroundSize: "contain",
  width: "100%",
  height: "100%",

  "@bp3": {},
});

export const PushAppImage = styled("img", {
  position: "absolute",
  left: 10,
  bottom: 0,
  width: "74px",
  height: "76px",

  "@bp3": {
    left: 0,
    bottom: 0,
    width: "88px",
    height: "110px",
  },
});

export const BackgroundImage = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  height: "100%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "50% 0%",
  backgroundSize: "cover",
});
